// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".empty-bookmarks-module__title--2jRaT{font-weight:700;font-size:21px;line-height:1.05;letter-spacing:-.02em}.empty-bookmarks-module__description--2pno5{font-size:14px;line-height:1.29;font-weight:400;letter-spacing:0}.empty-bookmarks-module__emptyContainer--2Cf2p{height:calc(100% - 60px);flex-direction:column;flex-shrink:0}.empty-bookmarks-module__emptyContainer--2Cf2p,.empty-bookmarks-module__topIconContainer--2aUpu{display:flex;justify-content:center;align-items:center}.empty-bookmarks-module__topIconContainer--2aUpu{width:80px;height:80px;padding:2px;border-radius:100px;background-color:#eff3fe;margin-bottom:15px}.empty-bookmarks-module__topIcon--2I8m7{width:40px;height:40px;flex-shrink:0;color:#1e54b7}.empty-bookmarks-module__title--2jRaT{color:#212121;text-align:center;margin-bottom:20px}.empty-bookmarks-module__description--2pno5{color:#757575;text-align:center;font-variant-numeric:lining-nums proportional-nums}.empty-bookmarks-module__icon--3TldJ{width:20px;height:20px;display:inline-block;color:#212121;vertical-align:middle}", ""]);
// Exports
exports.locals = {
	"title": "empty-bookmarks-module__title--2jRaT",
	"description": "empty-bookmarks-module__description--2pno5",
	"emptyContainer": "empty-bookmarks-module__emptyContainer--2Cf2p",
	"topIconContainer": "empty-bookmarks-module__topIconContainer--2aUpu",
	"topIcon": "empty-bookmarks-module__topIcon--2I8m7",
	"icon": "empty-bookmarks-module__icon--3TldJ"
};
module.exports = exports;
