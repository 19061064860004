import React from 'react';
import {render} from 'react-dom';

import {BookmarkList} from './bookmark-list';
import {BookmarkOpenButton} from './bookmark-open-button';

const renderBookmarksOpenButton = rootElement => {
  if (!rootElement) {
    return;
  }

  render(<BookmarkOpenButton />, rootElement);
};

const renderBookmarksList = rootElement => {
  if (!rootElement) {
    return;
  }

  render(<BookmarkList />, rootElement);
};

export {renderBookmarksOpenButton, renderBookmarksList};
