// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".bookmark-open-button-module__bookmarksAddButton--27AO4{position:relative}.bookmark-open-button-module__bookmarksAddButton--27AO4 .bookmark-open-button-module__tooltipContainer--jcINl{left:-45px;top:43px;right:auto}@media screen and (min-width:756px){.bookmark-open-button-module__bookmarksAddButton--27AO4 .bookmark-open-button-module__tooltipContainer--jcINl{left:-69px;top:43px;right:auto}}@media screen and (min-width:1152px){.bookmark-open-button-module__bookmarksAddButton--27AO4 .bookmark-open-button-module__tooltipContainer--jcINl{left:-97px;top:43px;right:auto}}.bookmark-open-button-module__bookmarksAddButton--27AO4 .bookmark-open-button-module__tooltip--sk_d4:before{top:-4px;left:54px}@media screen and (min-width:756px){.bookmark-open-button-module__bookmarksAddButton--27AO4 .bookmark-open-button-module__tooltip--sk_d4:before{top:-4px;left:79px}}@media screen and (min-width:1152px){.bookmark-open-button-module__bookmarksAddButton--27AO4 .bookmark-open-button-module__tooltip--sk_d4:before{top:-4px;left:calc(50% - 4px)}}.bookmark-open-button-module__bookmarksOpenListButton--12IEz{background-color:transparent;border:0;cursor:pointer;display:block;width:30px;height:30px;color:inherit}.bookmark-open-button-module__icon--3Ilvj{display:block;height:100%;color:inherit}", ""]);
// Exports
exports.locals = {
	"bookmarksAddButton": "bookmark-open-button-module__bookmarksAddButton--27AO4",
	"tooltipContainer": "bookmark-open-button-module__tooltipContainer--jcINl",
	"tooltip": "bookmark-open-button-module__tooltip--sk_d4",
	"bookmarksOpenListButton": "bookmark-open-button-module__bookmarksOpenListButton--12IEz",
	"icon": "bookmark-open-button-module__icon--3Ilvj"
};
module.exports = exports;
