const BOOKMARKS_OPEN_LIST = 'bookmarks-open-list';
const BOOKMARKS_TOOLTIP_ANIMATION = 10000;
const OPENED_ANIMATION_STATE = 'opened';
const PULSE_ANIMATION_STATE = 'pulse';
const CLOSED_ANIMATION_STATE = 'closed';
const BOOKMARKS_HEADER_TOOLTIP_COOKIE = 'bookmarks_header_tooltip_cookie';
const BOOKMARKS_ARTICLE_TOOLTIP_COOKIE = 'bookmarks_article_tooltip_cookie';
const THIRTY_DAYS = 30;
const THIRTY_DAYS_IN_HOURS = 24 * THIRTY_DAYS;
const BOOKMARKS_DB = 'bookmarks_db';
const BOOKMARKS_STORE = 'bookmarks_store';
const BOOKMARKS_ADD_EVENT = 'bookmarks_add_event';

export {
  BOOKMARKS_OPEN_LIST,
  BOOKMARKS_TOOLTIP_ANIMATION,
  OPENED_ANIMATION_STATE,
  PULSE_ANIMATION_STATE,
  CLOSED_ANIMATION_STATE,
  BOOKMARKS_HEADER_TOOLTIP_COOKIE,
  BOOKMARKS_ARTICLE_TOOLTIP_COOKIE,
  THIRTY_DAYS_IN_HOURS,
  BOOKMARKS_DB,
  BOOKMARKS_STORE,
  BOOKMARKS_ADD_EVENT
};
