import React from 'react';
import {useTranslation} from 'react-i18next';

import {Icon, ICON_NAMES} from '@nur-portal/ui-kit';

import {FEATURE_NAMES} from '../../growthbook/constants';

import styles from './empty-bookmarks.module.css';

const EmptyBookmarks = () => {
  const group = window.growthBook?.getFeatureValue(FEATURE_NAMES.BOOKMARKS_ARTICLES);
  const {t} = useTranslation('bookmarks');

  return (
    <div className={styles.emptyContainer}>
      <div className={styles.topIconContainer}>
        <Icon name={ICON_NAMES.BOOKMARK_ON_24} className={styles.topIcon} />
      </div>
      <div className={styles.title}>
        {t(`${group}.emptyList.title`)}
      </div>
      <div className={styles.description}>
        {t(`${group}.emptyList.description1`)}{' '}
        «<Icon name={ICON_NAMES.BOOKMARK_OFF_24} className={styles.icon} />»{' '}
        {t(`${group}.emptyList.description2`)}
      </div>
    </div>
  );
};

export {EmptyBookmarks};
