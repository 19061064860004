import React, {useEffect, useState} from 'react';
import clsx from 'clsx';

import {Icon, ICON_NAMES} from '@nur-portal/ui-kit';

import {
  BOOKMARKS_TOOLTIP_ANIMATION,
  CLOSED_ANIMATION_STATE,
  OPENED_ANIMATION_STATE,
  PULSE_ANIMATION_STATE} from './constants';

import styles from './bookmark-tooltip.module.css';

const BookmarkTooltip = ({className, containerClassName, text = '', onClose, onAnimationEnd}) => {
  const [animationState, setAnimationState] = useState(OPENED_ANIMATION_STATE);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationState(CLOSED_ANIMATION_STATE);
    }, BOOKMARKS_TOOLTIP_ANIMATION);

    return (() => {
      clearTimeout(timer);
    });
  }, []);

  const onCloseIconClick = () => {
    onClose && onClose();
    setAnimationState(CLOSED_ANIMATION_STATE);
  };

  const onAnimationEndHandler = () => {
    switch (animationState) {
      case OPENED_ANIMATION_STATE:
        setAnimationState(PULSE_ANIMATION_STATE);
        break;
      case CLOSED_ANIMATION_STATE:
        onAnimationEnd && onAnimationEnd();
        break;
      default:
        setAnimationState(PULSE_ANIMATION_STATE);
    }
  };

  return (
    <div className={clsx(containerClassName, styles.tooltipContainer)}>
      <div
        className={clsx(className, styles.tooltip, {
          [styles.pulse]: animationState === PULSE_ANIMATION_STATE,
          [styles.open]: animationState === OPENED_ANIMATION_STATE,
          [styles.closed]: animationState === CLOSED_ANIMATION_STATE
        })}
        onAnimationEnd={onAnimationEndHandler}
      >
        <div className={styles.tooltipText}>
          {text}
          <button className={styles.closeButtonIcon} onClick={onCloseIconClick}>
            <Icon name={ICON_NAMES.CLOSE_24} className={styles.closeIcon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export {BookmarkTooltip};
