import React from 'react';

import styles from './article-card.module.css';

const ArticleCard = ({onClick, id, category, image, link, date, formattedDate, title}) => (
  <article data-id={id} className={styles.articlePreview}>
    {
      image ?
        <picture className={styles.imageContainer}>
          <source
            type="image/webp"
            sizes={image.secondarySizes.sizes}
            srcSet={image.secondarySizes.srcset.webp}
          />
          <img
            className={styles.image}
            sizes={image.secondarySizes.sizes}
            srcSet={image.secondarySizes.srcset.jpeg}
            src={image.secondarySizes.src.jpeg}
            alt={image.alt}
            title={image.title}
            loading={image.loading}
            decoding="async"
          />
        </picture> :
        ''
    }
    <span className={styles.category}>{category}</span>
    <h3 className={styles.title}>
      <a className={styles.link} href={link} onClick={onClick}>
        {title}
      </a>
    </h3>
    <time className={styles.date} dateTime={date}>
      {formattedDate}
    </time>
  </article>
);

export {ArticleCard};
