import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Icon, ICON_NAMES} from '@nur-portal/ui-kit';

import {FEATURE_NAMES} from '../../growthbook/constants';
import {publishEvent} from '../../services/analytics/pub-sub';
import {ANALYTIC_EVENTS} from '../../services/analytics/pub-sub/constants';
import {existsCookie, setCookie} from '../../services/cookie';

import {BookmarkTooltip} from './bookmark-tooltip';
import {
  BOOKMARKS_ADD_EVENT,
  BOOKMARKS_HEADER_TOOLTIP_COOKIE,
  BOOKMARKS_OPEN_LIST,
  THIRTY_DAYS_IN_HOURS
} from './constants';

import styles from './bookmark-open-button.module.css';

const BookmarkOpenButton = () => {
  const [isShowModal, setShowModal] = useState(false);
  const group = window.growthBook?.getFeatureValue(FEATURE_NAMES.BOOKMARKS_ARTICLES);
  const {t} = useTranslation('bookmarks');

  const clickHandler = () => {
    publishEvent(ANALYTIC_EVENTS.BOOKMARKS_OPEN_LIST);
    document.dispatchEvent(new CustomEvent(BOOKMARKS_OPEN_LIST));
  };

  const handleCloseTooltip = () => {
    setShowModal(false);
    setCookie(BOOKMARKS_HEADER_TOOLTIP_COOKIE, true, THIRTY_DAYS_IN_HOURS);
  };

  const bookmarksChanges = async () => {
    if (existsCookie(BOOKMARKS_HEADER_TOOLTIP_COOKIE)) {
      return;
    }

    setShowModal(true);
  };

  useEffect(() => {
    document.addEventListener(BOOKMARKS_ADD_EVENT, bookmarksChanges);
    return () => {
      document.removeEventListener(BOOKMARKS_ADD_EVENT, bookmarksChanges);
    };
  }, []);

  return (
    <div className={styles.bookmarksAddButton}>
      <button
        onClick={clickHandler}
        className={styles.bookmarksOpenListButton}
      >
        <Icon className={styles.icon} name={ICON_NAMES.READING_LIST_30} />
      </button>
      {
        isShowModal &&
        <BookmarkTooltip
          onClose={handleCloseTooltip}
          onAnimationEnd={handleCloseTooltip}
          className={styles.tooltip}
          containerClassName={styles.tooltipContainer}
          text={t(`${group}.openBookmarkListTooltip`)}
        />
      }
    </div>
  );
};

export {BookmarkOpenButton};
