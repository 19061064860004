import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

import {Icon, ICON_NAMES} from '@nur-portal/ui-kit';

import {FEATURE_NAMES} from '../../growthbook/constants';
import {publishEvent} from '../../services/analytics/pub-sub';
import {ANALYTIC_EVENTS} from '../../services/analytics/pub-sub/constants';

import {ArticleCard} from './article-card';
import {BOOKMARKS_ADD_EVENT, BOOKMARKS_DB, BOOKMARKS_OPEN_LIST, BOOKMARKS_STORE} from './constants';
import {EmptyBookmarks} from './empty-bookmarks';
import {IndexedDBManager} from './indexed-db-manager';

import styles from './bookmark-list.module.css';

const EVEN_NUMBERS = 2;
const ODD_NUMBERS = 3;

const BookmarkList = () => {
  const [opened, setOpened] = useState(false);
  const [articles, setArticles] = useState(false);
  const containerRef = useRef(null);
  const group = window.growthBook?.getFeatureValue(FEATURE_NAMES.BOOKMARKS_ARTICLES);
  const {t} = useTranslation('bookmarks');

  const closeBookmarksHandler = () => {
    setOpened(false);
    document.body.style.overflowY = 'auto';
  };

  const handleClickOutside = event => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      closeBookmarksHandler();
    }
  };

  const openBookmarksHandler = () => {
    document.body.style.overflowY = 'hidden';
    setOpened(true);
  };

  const getArticleList = async () => {
    try {
      const dbManager = IndexedDBManager.getInstance(BOOKMARKS_DB, BOOKMARKS_STORE, 1);

      await dbManager.openDB();
      const articlesFromDb = await dbManager.getAllData();

      setArticles(articlesFromDb);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getArticleList();
  }, []);

  useEffect(() => {
    document.addEventListener(BOOKMARKS_OPEN_LIST, openBookmarksHandler);
    document.addEventListener(BOOKMARKS_ADD_EVENT, getArticleList);
    return () => {
      document.removeEventListener(BOOKMARKS_OPEN_LIST, openBookmarksHandler);
      document.removeEventListener(BOOKMARKS_ADD_EVENT, getArticleList);
    };
  }, []);

  const handleClick = event => {
    event.preventDefault();

    publishEvent(ANALYTIC_EVENTS.BOOKMARKS_CLICK_ARTICLE);

    document.location.href = event.target.href;
  };

  return (
    <section className={clsx(styles.bookmarksModal, {[styles.open]: opened})} onClick={handleClickOutside}>
      <div className={styles.container} ref={containerRef}>
        <div className={styles.head}>
          <div className={styles.title}>
            {
              t(`${group}.list.title`)}{articles.length > 0 ?
              `: ${articles.length}` :
              null
            }
          </div>
          <button onClick={closeBookmarksHandler} className={styles.closeButton}>
            <Icon name={ICON_NAMES.CLOSE_30} />
          </button>
        </div>
        {articles.length === 0 && <EmptyBookmarks />}
        {
          articles.length > 0 &&
          <ul
            className={clsx(styles.list,
              {
                [styles.oneItem]: articles.length === 1,
                [styles.evenItems]: articles.length % EVEN_NUMBERS === 0,
                [styles.oddItems]: articles.length % ODD_NUMBERS === 0
              }
            )}
          >
            {
              articles.map(bookmark =>
                (
                  <li className={styles.item} key={bookmark.id}>
                    <ArticleCard {...bookmark} onClick={handleClick} />
                  </li>
                )
              )
            }
          </ul>
        }
      </div>
    </section>
  );
};

export {BookmarkList};
